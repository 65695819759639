import React, { useState } from 'react';
import styled from "styled-components"
import Iframe from "react-iframe"
import { Link } from "gatsby"
import FadeIn from 'react-fade-in';
import Alert from "react-bootstrap/Alert";
import { Helmet } from "react-helmet";

import { motion } from "framer-motion"

import Layout from "../components/common/layout/layout"
import SEO from "../components/common/layout/seo"
import Navigation from "../components/common/navigation/navigation"

import Features from "../components/common/features"
import ProductSteps from "../components/sections/productsteps"
import Categories from "../components/sections/categories"

import ImageSlider from "../components/common/imageslider"
import Footer from "../components/sections/footer"

import AboutHeader from "../components/sections/about/aboutheader"

import TextColumn from "../components/common/textcolumn"

import { SectionDivider, HalfColumn, TwoColumn } from "../components/global"

function AlertDismissible() {
  const [show, setShow] = useState(true);

  if (show) {
    // return (
    //   <AlertNoM variant="light" onClose={() => setShow(false)} dismissible>
    //     <div>Dili loan options are currently available in Mumbai and Pune.</div>
    //   </AlertNoM>
    // );
  }
  return <div></div>;
}

const IndexPage = () => (
  <Layout>
    <Helmet>
      <title>Dili – No Cost EMI for Personal & Professional Growth</title>
      <meta name="description" content="India’s first FinTech company focused on financing the personal & professional growth of the young & progressive at no cost EMI." />
      <script src="https://app.wotnot.io/chat-widget/7TjRGbCPWUpE111043578737NTj7Oir8.js" defer></script>
    </Helmet>
    <Navigation></Navigation>
    <AlertDiv><AlertDismissible></AlertDismissible></AlertDiv>
    <FadeIn>
    <HeaderColumn>
      <TextColumn></TextColumn>
      <HalfColumn><ImageSlider /></HalfColumn>
    </HeaderColumn>
    <SectionDivider>The Dili story</SectionDivider>
    <AboutHeader></AboutHeader>
    <SectionDivider>Put your dreams on the fast track with Dili</SectionDivider>
    <Features></Features>
    <SectionDivider>Come, be a better version of yourself in 4 simple steps</SectionDivider>
    <ProductSteps></ProductSteps>
    <SectionDivider>A myriad opportunities to build and enjoy a bigger life</SectionDivider>
    <Categories></Categories>
    </FadeIn>
    <Footer></Footer>
  </Layout>
)

{/* COMMENT */}

export default IndexPage

export const MotionTest = styled(motion.button)`
    background: blue;
    border-radius: 30px;
    width: 150px;
    height: 150px;
    margin: 100px;
`

export const HeaderColumn = styled.div`
    display: flex;

    @media (max-width: ${props => props.theme.screen.md}) {
      flex-direction: column;
      height: auto;
    };

    @media (max-width: ${props => props.theme.screen.sm}) {
      flex-direction: column;
      height: auto;
    };

    @media (max-width: ${props => props.theme.screen.xs}) {
      flex-direction: column;
      height: auto;
    };
`

export const AlertDiv = styled.div`
    position: fixed;
    bottom: 0;
    width: 100%;
    z-index: 999;
`

export const AlertNoM = styled(Alert)`
    margin: 0;
    text-align: center;
    font-size: 16px;
    opacity: 90%;
`